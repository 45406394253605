import React from 'react';

const ItalicIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <path d='M7 3V2h4v1H9.753l-3 10H8v1H4v-1h1.247l3-10H7z' />
    </svg>
  );
};

export default ItalicIcon;
