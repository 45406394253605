import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { SortByInterface, User, UserFilters } from '__generated__/graphql';
import { useAuthContext } from 'context/auth.context';
import { GET_USERS } from 'graphql/users/queries/getUsers.gql';

export const useGetUsersQuery = ({
  sortBy = { sortByColumn: 'firstName' },
  filters,
  ignorePermissions = false,
}: {
  sortBy?: SortByInterface;
  filters?: UserFilters;
  ignorePermissions?: boolean;
}): {
  users: User[];
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { userTenantId: tenantId } = useAuthContext();

  const { data, error, loading, refetch } = useQuery(GET_USERS, {
    skip: !tenantId,
    variables: {
      usersInput: {
        tenantId,
        sortBy,
        filters,
        ignorePermissions,
      },
    },
  });

  return { users: data?.users ?? [], loading, error, refetch };
};
