import React from 'react';

const OrderedListIcon = () => {
  return (
    <svg width='13' height='13' xmlns='http://www.w3.org/2000/svg'>
      <g fill='#000' fillRule='evenodd'>
        <path d='M4.202 1.466h8.15c.338 0 .612-.322.612-.72 0-.397-.274-.72-.612-.72h-8.15c-.338 0-.611.323-.611.72 0 .398.273.72.611.72zM12.352 5.783h-8.15c-.338 0-.611.322-.611.72 0 .397.273.72.611.72h8.151c.338 0 .612-.323.612-.72 0-.398-.274-.72-.612-.72zM12.352 11.54h-8.15c-.338 0-.611.322-.611.72 0 .396.273.719.611.719h8.151c.338 0 .612-.323.612-.72 0-.397-.274-.719-.612-.719zM.767 1.249v1.802c0 .195.136.343.315.343.176 0 .315-.15.315-.343V.356c0-.19-.133-.339-.302-.339-.148 0-.223.118-.247.156a.228.228 0 0 0-.003.001L.579.621a.474.474 0 0 0-.098.273c0 .194.128.351.286.355zM.352 8.19H1.55c.157 0 .285-.162.285-.362 0-.198-.128-.359-.285-.359H.68v-.006c0-.107.21-.281.378-.422.336-.278.753-.625.753-1.226 0-.57-.376-1-.874-1-.477 0-.836.385-.836.897 0 .297.164.402.305.402.2 0 .321-.176.321-.346 0-.106.023-.228.204-.228.243 0 .25.254.25.283 0 .228-.252.442-.495.649-.301.255-.642.544-.642.997v.384c0 .205.159.343.308.343zM1.77 10.543c0-.59-.296-.931-.814-.931-.68 0-.859.57-.859.872 0 .351.222.39.318.39.185 0 .31-.148.31-.366 0-.084.026-.181.224-.181.142 0 .2.024.2.267 0 .237-.043.263-.213.263-.164 0-.288.152-.288.354 0 .2.125.35.291.35.225 0 .27.108.27.283v.075c0 .294-.097.35-.277.35-.248 0-.267-.15-.267-.197 0-.174-.098-.35-.317-.35-.192 0-.307.141-.307.378 0 .43.313.888.895.888.564 0 .901-.4.901-1.07v-.074c0-.274-.074-.502-.214-.666.096-.163.148-.38.148-.635z' />
      </g>
    </svg>
  );
};

export default OrderedListIcon;
