import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers($usersInput: UsersInput!) {
    users(usersInput: $usersInput) {
      id
      firstName
      lastName
      email
      phoneNumber
      isActive
    }
  }
`;
