import React from 'react';

const UnorderedListIcon = () => {
  return (
    <svg width='16' height='14' xmlns='http://www.w3.org/2000/svg'>
      <g fill='#000' fillRule='evenodd'>
        <path d='M1.72 3.427c.951 0 1.722-.768 1.722-1.708S2.67.01 1.72.01C.77.008 0 .775 0 1.715c0 .94.774 1.711 1.72 1.711zm0-2.625c.51 0 .922.412.922.914a.92.92 0 1 1-1.842 0c0-.503.415-.914.92-.914zM1.72 8.703c.951 0 1.722-.768 1.722-1.708S2.67 5.287 1.72 5.287C.77 5.287 0 6.054 0 6.995s.774 1.708 1.72 1.708zm0-2.622c.51 0 .922.412.922.914a.92.92 0 0 1-1.842 0c0-.502.415-.914.92-.914zM1.72 13.982c.951 0 1.722-.768 1.722-1.708 0-.943-.774-1.709-1.722-1.709-.947 0-1.721.767-1.721 1.709 0 .94.774 1.708 1.721 1.708zm0-2.625c.51 0 .922.412.922.914a.92.92 0 1 1-1.842 0c0-.502.415-.914.92-.914zM5.744 2.115h9.845a.4.4 0 0 0 .401-.399.4.4 0 0 0-.401-.399H5.744a.4.4 0 0 0-.402.399.4.4 0 0 0 .402.399zM5.744 7.394h9.845a.4.4 0 0 0 .401-.399.4.4 0 0 0-.401-.398H5.744a.4.4 0 0 0-.402.398.4.4 0 0 0 .402.399zM5.744 12.67h9.845a.4.4 0 0 0 .401-.399.4.4 0 0 0-.401-.399H5.744a.4.4 0 0 0-.402.4.4.4 0 0 0 .402.398z' />
      </g>
    </svg>
  );
};

export default UnorderedListIcon;
