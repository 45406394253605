/* eslint-disable react/prop-types */
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  Editor,
  convertToRaw,
  RichUtils,
  Modifier,
} from 'draft-js';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  buildEditorStateFromHtml,
  buildEditorStateFromText,
  handleRemoveAtSignBeforeCursor,
  handleRichTextWithUserMentionState,
  Mention,
  MentionStyles,
  replaceMentionsWithFallbackContent,
} from 'utils/userTagging';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box, ClickAwayListener, Popper, styled, ToggleButton } from '@mui/material';
import UserListPicker, { UserTaggingInfo } from 'components/UserTagging/UserListPicker';
import BoldIcon from 'components/Icon/BoldIcon';
import ItalicIcon from 'components/Icon/ItalicIcon';
import UnderlineIcon from 'components/Icon/UnderlineIcon';
import UnorderedListIcon from 'components/Icon/UnorderedListIcon';
import OrderedListIcon from 'components/Icon/OrderedListIcon';

export interface MentionComponentProps {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
  darkMode: boolean;
}

export const MentionComponent: React.FC<MentionComponentProps> = ({
  children,
  contentState,
  entityKey,
  darkMode,
}) => {
  const { user } = contentState.getEntity(entityKey).getData();
  return <span style={darkMode ? MentionStyles.darkMode : MentionStyles.default}>{children}</span>;
};

export const findMentionEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION';
  }, callback);
};

const StyledToggleButton = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLButtonElement, React.ComponentPropsWithoutRef<typeof ToggleButton>>(
    (props, ref) => <ToggleButton ref={ref} {...props} />
  )
)(({ theme }) => ({
  border: 'none',
  color: '#000000',
  fontWeight: 'normal',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  '& .MuiSvgIcon-root': {
    fontWeight: 'normal',
    width: '100%',
    height: '100%',
  },
}));

interface RichTextWithUserMentionProps {
  disabled?: boolean;
  initialValue: string;
  // Used to force editor's re-initialization when a user removes a textfield (isMultiple)
  forceValueUpdate: { dt: number; mentions: Mention[] } | undefined;
  users: UserTaggingInfo[];
  taggedUsers: Mention[];
  onChange: (text: string, mentions: Mention[]) => void;
  resetEditor?: (fn: () => void) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  enableFormatting?: boolean;
}

export type RichTextWithUserMentionHandle = {
  focusEditor: () => void;
};

export const RichTextWithUserMention = React.memo(
  forwardRef<RichTextWithUserMentionHandle, RichTextWithUserMentionProps>(
    (
      {
        initialValue,
        forceValueUpdate,
        users,
        disabled = false,
        onChange,
        taggedUsers,
        resetEditor,
        onFocus = () => {},
        onBlur = () => {},
        enableFormatting = false,
      },
      ref
    ) => {
      const decorator = useMemo(
        () =>
          new CompositeDecorator([
            {
              strategy: findMentionEntities,
              component: (props: MentionComponentProps) => <MentionComponent {...props} />,
            },
          ]),
        []
      );

      const handleResetEditor = useCallback(() => {
        const emptyContentState = ContentState.createFromText('');
        const newEditorState = EditorState.push(editorState, emptyContentState, 'remove-range');
        setEditorState(newEditorState);
      }, []);

      const [searchQuery, setSearchQuery] = useState('');
      const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
      const editorRef = useRef<Editor>(null);
      const [isPopperOpen, setPopperOpen] = useState(false);
      const [initilialized, setInitilialized] = useState(false);
      const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
      const mentionButtonRef = useRef<HTMLButtonElement | null>(null);
      const editorWrapperRef = useRef<HTMLDivElement | null>(null);
      const [cursorPosition, setCursorPosition] = useState<{ top: number; left: number } | null>(
        null
      );
      const filteredUsers = useMemo(() => {
        return users.filter((user) => user.name.toLowerCase().includes(searchQuery.toLowerCase()));
      }, [searchQuery, users]);

      if (resetEditor) {
        resetEditor(handleResetEditor);
      }

      const focusEditor = () => {
        editorRef.current?.focus();
      };

      useImperativeHandle(ref, () => ({ focusEditor }));

      // This ensures that Popper always has a valid anchorEl
      useEffect(() => {
        if (editorWrapperRef.current && !anchorEl) {
          setAnchorEl(editorWrapperRef.current);
        }
      }, [anchorEl]);

      const handleMentionButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        // TODO refactor code below
        // It's a Duplicate of CommentInput::handleDisplayUserTaggingPicker
        // But not merged in develop branch yet

        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        // Get the current block and its text
        const blockKey = selectionState.getStartKey();
        const block = contentState.getBlockForKey(blockKey);
        const blockText = block.getText();

        // Get the cursor position
        const cursorOffset = selectionState.getStartOffset();

        // Determine if a space needs to be added
        const needsSpace = cursorOffset > 0 && blockText[cursorOffset - 1] !== ' ';

        // Add a space if necessary, followed by the @ symbol
        const textToInsert = needsSpace ? ' @' : '@';

        // Insert the text at the current selection
        const contentStateWithSymbol = Modifier.insertText(
          contentState,
          selectionState,
          textToInsert
        );

        // Update the editor state with the new content state
        const newEditorState = EditorState.push(
          editorState,
          contentStateWithSymbol,
          'insert-characters'
        );

        const updatedEditorState = EditorState.forceSelection(
          newEditorState,
          contentStateWithSymbol.getSelectionAfter()
        );

        setPopperOpen(true);

        // Set focus back to the editor and update state
        setEditorState(updatedEditorState);

        // Manually call handleEditorStateChange with the updated state
        handleEditorStateChange(updatedEditorState);
      };

      const handlePopperClose = (event: MouseEvent | TouchEvent) => {
        if (
          anchorEl &&
          (anchorEl.contains(event.target as Node) ||
            editorWrapperRef.current?.contains(event.target as Node) ||
            mentionButtonRef.current?.contains(event.target as Node))
        ) {
          return; // Ignore clicks on the button itself and editor
        }

        const response = handleRemoveAtSignBeforeCursor(editorState, searchQuery);
        if (response !== undefined) {
          const { state, content } = response;
          setEditorState(EditorState.forceSelection(state, content.getSelectionAfter()));
        }

        setPopperOpen(false);
      };

      const initializeEditor = (mentionList: Mention[]) => {
        let editorStateWithDecorator;

        if (enableFormatting) {
          if (initialValue.trim().length) {
            const contentState = buildEditorStateFromHtml(initialValue, mentionList);
            editorStateWithDecorator = EditorState.createWithContent(contentState, decorator);
          } else {
            editorStateWithDecorator = EditorState.createEmpty(decorator);
          }
        } else {
          editorStateWithDecorator = EditorState.createWithContent(
            buildEditorStateFromText(initialValue, mentionList).getCurrentContent(),
            decorator
          );
        }
        setEditorState(editorStateWithDecorator);
      };

      useEffect(() => {
        if (taggedUsers === undefined) return;

        if (!initilialized && initialValue !== undefined) {
          initializeEditor(taggedUsers);
          setInitilialized(true);
        }
      }, [taggedUsers]);

      useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const { text, mentions } = replaceMentionsWithFallbackContent(
          contentState,
          enableFormatting,
          taggedUsers
        );
        if (initilialized) onChange(text, mentions);
      }, [editorState]);

      useEffect(() => {
        if (forceValueUpdate !== undefined) {
          initializeEditor(forceValueUpdate.mentions);
        }
      }, [forceValueUpdate]);

      const handleEditorStateChange = (state: EditorState) => {
        setEditorState(state);

        const contentState = state.getCurrentContent();

        const selection = state.getSelection();
        const block = contentState.getBlockForKey(selection.getStartKey());
        const blockText = block.getText();

        const cursorPosition = selection.getStartOffset();
        const textBeforeCursor = blockText.substring(0, cursorPosition);
        const atIndex = textBeforeCursor.lastIndexOf('@');

        if (atIndex !== -1) {
          const charBeforeAt = textBeforeCursor[atIndex - 1];
          const isValidAtSymbol = !charBeforeAt || !/[a-zA-Z0-9_]/.test(charBeforeAt);

          if (isValidAtSymbol) {
            const query = textBeforeCursor.slice(atIndex + 1);
            setSearchQuery(query);

            // set cursor position
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const rect = range.getBoundingClientRect();

              setCursorPosition({ top: rect.top, left: rect.left });
            }

            setPopperOpen(true);
          } else {
            setPopperOpen(false);
            setSearchQuery('');
          }
        } else {
          setPopperOpen(false);
          setSearchQuery('');
        }
      };

      const handleKeyPress = useCallback((e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleMentionButtonClick(e as unknown as React.MouseEvent<HTMLDivElement>);
        }
      }, []);

      const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.currentTarget.style.backgroundColor = '#dddce5';
      }, []);

      const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.currentTarget.style.backgroundColor = '#fafafa';
      }, []);

      const isTextSelected = useMemo(
        () => !editorState.getSelection().isCollapsed(),
        [editorState]
      );

      const customButton = (
        <div
          key='mention-button'
          role='button'
          tabIndex={0}
          onClick={(event) => {
            if (isTextSelected) {
              event.preventDefault();
              return;
            }

            handleMentionButtonClick(event);
          }}
          onKeyDown={handleKeyPress}
          style={{
            display: 'inline-block',
            cursor: isTextSelected ? 'not-allowed' : 'pointer',
            opacity: isTextSelected ? 0.5 : 1,
            borderRadius: '2px',
            lineHeight: '24px',
            textAlign: 'center',
            userSelect: 'none',
            minWidth: '25px',
            height: '100%',
            padding: '0px 0.875rem',
          }}
          onMouseDown={(event) => event.preventDefault()} // Prevents focus shift
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          @
        </div>
      );

      const handleUserClick = useCallback(
        (user: Mention) => {
          const updatedEditorState: EditorState = handleRichTextWithUserMentionState(
            user,
            editorState,
            searchQuery
          );

          setEditorState(updatedEditorState);
          setPopperOpen(false);
          setSearchQuery('');

          setTimeout(() => {
            editorRef.current?.focus();
          }, 0);
        },
        [editorState, searchQuery]
      );

      const UserListPopper = useMemo(
        () => (
          <UserListPicker
            useAbsolutePositioning={false}
            users={filteredUsers}
            onUserClick={handleUserClick}
            darkMode={false}
          />
        ),
        [filteredUsers, handleUserClick]
      );

      // Handle inline style
      const toggleInlineStyle = (style: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
      };

      // Handle block style
      const toggleBlockType = (blockType: string) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
      };

      return (
        <div className=''>
          {!disabled && (
            <Box
              onMouseDown={(e) => e.preventDefault()}
              sx={{
                display: 'flex',
                textAlign: 'left',
                border: '1px solid #CECECE',
                // backgroundColor: '#fafafa',
                borderRadius: '8px',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '0.0625rem solid rgb(202, 202, 202)',
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                backgroundColor: 'rgb(250, 250, 250)',
                borderTopLeftRadius: '0.625rem',
                borderTopRightRadius: '0.625rem',
                padding: '0px 0.875rem',
                height: '25px',
                position: 'sticky',
                top: 0,
                zIndex: 2,
              }}
            >
              {enableFormatting && (
                <>
                  <StyledToggleButton
                    sx={{ border: 'none' }}
                    onClick={() => toggleBlockType('header-one')}
                    value='H1'
                  >
                    H1
                  </StyledToggleButton>
                  <StyledToggleButton onClick={() => toggleBlockType('header-two')} value='H2'>
                    H2
                  </StyledToggleButton>
                  <StyledToggleButton onClick={() => toggleBlockType('header-three')} value='H3'>
                    H3
                  </StyledToggleButton>

                  <StyledToggleButton onClick={() => toggleInlineStyle('BOLD')} value='BOLD'>
                    <BoldIcon />
                  </StyledToggleButton>
                  <StyledToggleButton onClick={() => toggleInlineStyle('ITALIC')} value='ITALIC'>
                    <ItalicIcon />
                  </StyledToggleButton>
                  <StyledToggleButton
                    onClick={() => toggleInlineStyle('UNDERLINE')}
                    value='UNDERLINE'
                  >
                    <UnderlineIcon />
                  </StyledToggleButton>

                  <StyledToggleButton
                    onClick={() => toggleBlockType('unordered-list-item')}
                    value='unordered-list-item'
                  >
                    <UnorderedListIcon />
                  </StyledToggleButton>
                  <StyledToggleButton
                    onClick={() => toggleBlockType('ordered-list-item')}
                    value='ordered-list-item'
                  >
                    <OrderedListIcon />
                  </StyledToggleButton>

                  <StyledToggleButton
                    ref={mentionButtonRef}
                    onClick={handleMentionButtonClick}
                    onKeyDown={handleKeyPress}
                    value='ordered-list-item'
                  >
                    @
                  </StyledToggleButton>
                </>
              )}
            </Box>
          )}

          <Box
            ref={editorWrapperRef}
            sx={{
              lineHeight: 1.6,
              overflowY: 'auto',
              padding: '0px 0.875rem',
            }}
          >
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={handleEditorStateChange}
              placeholder='Answer here'
              readOnly={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
              spellCheck={true}
            />
          </Box>

          <ClickAwayListener onClickAway={handlePopperClose}>
            <Popper
              open={isPopperOpen && filteredUsers.length > 0}
              anchorEl={anchorEl}
              placement={cursorPosition ? 'top-start' : 'bottom-start'}
              sx={{
                zIndex: 11,
              }}
              style={
                cursorPosition
                  ? {
                      position: 'absolute',
                      top: cursorPosition.top + window.scrollY,
                      left: cursorPosition.left,
                    }
                  : undefined
              }
            >
              {UserListPopper}
            </Popper>
          </ClickAwayListener>
        </div>
      );
    }
  )
);

RichTextWithUserMention.displayName = 'RichTextWithUserMention';
